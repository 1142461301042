import React from 'react';
import './Home.css';
import { Box, Button, styled, Switch, TextField } from '@mui/material';

const Home: React.FC = () => {
    const [charge, setCharge] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [isStreamerVisible, setIsStreamerVisible] = React.useState(false);

    const handleChargeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCharge(event.target.value);
    };

    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDuration(event.target.value);
    };


    const toggleSection = () => {
        setIsStreamerVisible((prev) => !prev);
    };

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 50,
        height: 30,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 30,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(20px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 3,
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#dc1717',
              ...theme.applyStyles('dark', {
                backgroundColor: '#dc1717',
              }),
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 24,
          height: 24,
          borderRadius: '50%',
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 12,
          opacity: 1,
          backgroundColor: '#6b09c7',
          boxSizing: 'border-box',
          ...theme.applyStyles('dark', {
            backgroundColor: '#6b09c7',
          }),
        },
      }));

    return (
        <>
        <div className="head">
            <div
                className={`streamer ${!isStreamerVisible ? 'section-enter' : 'section-exit'}`}
                style={{ display: !isStreamerVisible ? 'flex' : 'none' }}
            >
                <img src="/streamer-laptop.png" className='head-img' alt="Home" />
                <div className="text">
                    <h1 className="heading">for streamers</h1>
                    <p>Now sell slots for Branded Content on your
                    live channels. Direct and within seconds.</p>
                    {/* <Button variant="contained" size="large" style={{ marginTop: '20px' }}
                        onClick={() => window.location.href = '/signup'}>
                        Become a Streamer
                    </Button> */}
                    <img src="/streamer-tab.svg" alt="Streamer Tab" style={{ width: '100%', maxWidth: '500px' }} />
                </div>
            </div>

            <div
                className={`advertiser ${isStreamerVisible ? 'section-enter' : 'section-exit'}`}
                style={{ display: !isStreamerVisible ? 'none' : 'flex' }}
            >
                <div className="text">
                    <h1 className="heading">for Advertisers</h1>
                    <p>Now reach to your desired audiences in a click, in the most engaging way possible.</p>
                    {/* <Button variant="contained" size="large" style={{ marginTop: '20px' }}
                        onClick={() => window.location.href = '/signup'}>
                        Become an Advertiser
                    </Button> */}
                    <img src="/advertiser-tab.svg" alt="Streamer Tab" style={{ width: '100%', maxWidth: '500px' }} />
                </div>
                <img src="/adv-laptop.png" className='head-img' alt="Home"/>
            </div>
        </div>
        
        <div className="intro">
            <div className="switch heading">
                <span>streamer</span>
                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onClick={toggleSection} checked={isStreamerVisible}/>
                <span>Advertiser</span>
            </div>
            <span className='heading' style={{maxWidth:'600px', textAlign:'center', marginTop:'40px'}}>Instant Brand Deals for Live Streamers</span>
            <div className="video-container" style={{ marginTop: '40px', textAlign: 'center', width: '100%' }}>
                {/* <video
                    width="80%"
                    height={window.innerWidth > 600 ? '400' : '200'}
                    controls
                    autoPlay
                    loop
                    style={{ maxWidth: '100%', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                >
                    <source src="https://www.youtube.com/embed/2ORdWLiC88o" type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                <iframe 
                    width="100%"
                    height={window.innerWidth > 600 ? '500' : '200'}
                    src="https://www.youtube.com/embed/105Enwiwx-s?si=tevqFKUa_5yHHcGc&amp;controls=0&autoplay=1" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    rel="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    style={{ maxWidth: '850px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                ></iframe>
            </div>
        </div>

        
            {/* share */}
            <div className="share">
            <img src="/sharegirl.png" className="sharegirl-img" alt="Share Girl" />

            <div className="content">
                <h1 className="heading">Revenue share</h1>
                <p>
                    You already did all the hard work!
                </p>
                <p>
                    You built an amazing audience and developed a brand that people love.
                </p>
                <p>
                    With the <strong>STREAMERS</strong> platform we want to help you to turn all of this into a sustainable business.
                </p>
                <p>
                    There’s no question about it - you deserve the lion’s share.
                </p>
                <Button
                    variant="contained"
                    size="large"
                    style={{ marginTop: '20px' }}
                    onClick={() => window.location.href = '/signup'}
                >
                    Join Now
                </Button>
            </div>

            <img src="/share.png" className="head-img" alt="Share" />
        </div>

            <Box
            sx={{
                backgroundColor: '#f3e2f5',
                padding: '20px',
                display: 'flex',
                alignContent: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '90vh', gap: '30px'
            }}
            >
                <div className="content" style={{
                    maxWidth:'400px'}}>
                    <h1 className='heading' style={{textTransform:'uppercase', fontStyle: 'italic', fontFamily:'monospace'}}>
                        LET'S TALK
                        <br />
                        ABOUT MONEY!!
                    </h1>
                    <p>
                    Play around with the deal calculator to get an idea of your revenue potential.
                    </p>
                    <p>
                    Simply enter the data in the deal calculator and get more information about how the money flows.
                    </p>
                    <Button 
                        variant="contained" 
                        size="large" 
                        style={{ marginTop: '20px' }} 
                        onClick={() => window.location.href = '/signup'}
                    >
                        SetUp a Campaign
                    </Button>
                </div>
                <div className="calculator" style={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '400px',
                    padding: '20px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    boxShadow: 'rgb(88 118 222) 9px 9px 0px, rgb(68 78 129) 19px 19px 0px',
                    margin: '20px',
                }}>
                    <div className="info">
                        <h3>Deal Calculator</h3>
                        <p>Calculate your potential earnings and charges</p>
                    </div>

                    <div className="input" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <TextField
                            id="charge"
                            label="Streamer's Charge"
                            variant="outlined"
                            value={charge}
                            onChange={handleChargeChange}
                            style={{ width: '100%'}}/>
                        <TextField
                            id="duration"
                            label="Advertisement Duration"
                            variant="outlined"
                            value={duration}
                            onChange={handleDurationChange}
                            style={{ width: '100%'}}/>
                        
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '20px',
                                alignItems: 'center',
                                maxWidth: '300px',
                                border: '1px dashed black',
                                padding: '10px',
                            }}
                        >
                            <div>Advertisement Charges: </div>
                            <div><strong>{(Number(duration) * Number(charge)).toFixed(2)}</strong></div>
                            <div>Streamers Earning: </div>
                            <div><strong>{(Number(duration) * Number(charge) * 0.7).toFixed(2)}</strong></div>
                        </Box>
                    </div>
                </div>

            </Box>

{/* contACT */}
        <div className="gethelp">
            <div className="content">
                <h1 className='heading'>Get Help</h1>
                <p>
                    We're here to help and we're here 24/7.
                </p>
                <p>
                    Please don't hesitate to get in touch. We're reading every message.
                </p>
                <Button 
                    variant="contained" 
                    size="large" 
                    style={{ marginTop: '20px', backgroundColor:'transparent', color:'black', fontWeight:'bold', border:'2px solid black' }} 
                    onClick={() => window.location.href = '/contact'}
                >
                    Contact Us
                </Button>
            </div>
            <img src="/gethelp.png" className="head-img" alt="Get Help" />
        </div>
        </>
    );
};

export default Home;