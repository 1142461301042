import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { BACKEND_API_URL } from '../../config/env';
import { getUsernameById } from '../../api/userService';

const Player = () => {
  const { userId } = useParams<{ userId: string }>();
  const { pin } = useParams<{ pin: string }>();
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timer, setTimer] = useState<number>(15);
  const [isLive, setIsLive] = useState(false);
  const [isUsernameLoading, setIsUsernameLoading] = useState(false);
  const [timeout, setTimeout] = useState<number>(15);
  // const [Size, setSize] = useState<Number>(75);
  // const [Orientation, setOrientation] = useState(false);

  const fetchUsername = async () => {
    setIsUsernameLoading(true);
    try {
      if (userId) {
        const result = await getUsernameById(userId);
        if ('timeout' in result && 'isLive' in result) {
          setTimeout(result.timeout || 15);
          setIsLive(result.isLive || false);
        }
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    } finally {
      setIsUsernameLoading(false);
    }
  };

  useEffect(() => {
    fetchUsername();
  }, [userId]);

  const fetchNextVideo = async () => {
    try {
      fetchUsername();
      const response = await axios.get(`${BACKEND_API_URL}/api/v1/media/video/${userId}/${pin}`);
      const fetchedVideo = response.data.url;

      if (fetchedVideo) {
        setCurrentVideo(fetchedVideo);
        setTimer(15); // Start countdown for next playback
        setIsLoaded(true);
      } else {
        setCurrentVideo(null);
      }
    } catch (error) {
      console.error('Error fetching video:', error);
      setCurrentVideo(null);
    }
  };

  useEffect(() => {
    if (isPlaying || isLoaded) return; // Skip fetching if video is playing

    // fetchNextVideo();
    const intervalId = setInterval(() => {
      if (!isPlaying) {
        console.log('Fetching next video...');
        fetchNextVideo();
      }
    }, timeout * 60 * 1000); // Check every 10 seconds

    return () => clearInterval(intervalId);
  }, [userId, isPlaying, isLoaded]);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout | null = null;

    if (isLoaded && !isPlaying) {
      // Start countdown only if video is loaded but not playing yet
      countdownInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 1) {
            return prev - 1;
          } else {
            setIsPlaying(true); // Start video when countdown ends
            setIsLoaded(false); // Hide timer and display video
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (countdownInterval) clearInterval(countdownInterval);
    };
  }, [isLoaded, isPlaying]);

  const handleVideoEnded = () => {
    setIsPlaying(false); // Stop playing
    setCurrentVideo(null); // Clear current video
    setIsLoaded(false); // Reset to trigger re-fetch after timeout

    // Fetch a new video after timeout
    // setTimeout(fetchNextVideo, time); // 5 seconds delay before fetching a new video
  };

  const containerStyle: React.CSSProperties = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    overflow: 'hidden',
  };

  const messageStyle: React.CSSProperties = {
    color: '#fff',
    fontSize: 'small',
    textAlign: 'center',
    padding: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '8px',
    maxWidth: '80%',
  };

  if (isUsernameLoading) {
    return (
      <div style={containerStyle}>
        <CircularProgress />
      </div>
    );
  }

  if (!isLive) {
    return (
      <div style={containerStyle}>
        <div style={messageStyle}>
          Streamer is not live. Go to Dashboard &gt; Ads Setup &gt; Live Stream and toggle the stream button.
        </div>
      </div>
    );
  }

  if (isLoaded && !isPlaying) {
    return (
      <div style={containerStyle}>
        <div style={messageStyle}>
          Next Ad in {timer} seconds...
        </div>
      </div>
    );
  }

  return (
    <>
      {currentVideo && isPlaying && (
        <video
          style={{
            position: 'absolute',
            // width: Orientation ? 'auto' : `${Size}%`,
            // height: Orientation ? `${Size}%` : 'auto',
            maxHeight: '60%',
            maxWidth: '40%',
            objectFit: 'contain',
            top: 0,
            right: 0,
            zIndex: 10,
        }}
          autoPlay
          muted
          onEnded={handleVideoEnded} // Triggered when the video ends
        >
          <source src={currentVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};

export default Player;
