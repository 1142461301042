import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { updateUser } from "../../api/userService";

interface ProfileProps {
  user: any;
}

const Profile: React.FC<ProfileProps> = ({ user }) => {
  const [name, setName] = useState<string>(user.name);
  const [phoneNumber, setPhoneNumber] = useState<number | string>(user.phoneNumber);
  const [channelLink, setChannelLink] = useState<string>(user.channelLink);
  const [ifsc, setIfsc] = useState<string>(user.ifsc);
  const [accountNumber, setAccountNumber] = useState<string>(user.accountNumber);
  const [accountName, setAccountName] = useState<string>(user.accountName);
  const [bankDetails, setBankDetails] = useState<string>(user.bankDetails);
  const [billingError, setBillingError] = useState<string>('');
  const [profileError, setProfileError] = useState<string>('');


  const handleChannelLinkChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannelLink(e.target.value);
  }

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await updateUser({ name, phoneNumber, channelLink });
      if (response.status === 200) {
        setProfileError("Details updated successfully");
          } else {
            setProfileError(`Error updating details`);
          }

      } catch (error) {
        setProfileError("Error updating details");
      }
    }

  const handleAccountSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await updateUser({ ifsc, accountNumber, accountName, bankDetails });
      if (response.status === 200) {
        setBillingError("Details updated successfully");
          } else {
            setBillingError(`Error updating details`);
          }

      } catch (error) {
        setBillingError("Error updating details");
      }
    }

  return (
    <>
    <div>
      <h2>Profile</h2>
    <form onSubmit={handleProfileSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TextField variant="standard" required label="name" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
      <TextField variant="standard" label="Phone Number (with country code)" type="text" name="phoneNum" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      {/* <TextField variant="standard" required label="About" type="textarea" multiline name="about" value={about || ''} onChange={(e) => setAbout(e.target.value)} /> */}
      <TextField variant="standard" required label="Channel Link" type="text" name="channelLink" value={channelLink || ''} onChange={(e) => handleChannelLinkChange(e)} />
      {/* {channelLink.slice(1).map((link, index) => (
      <TextField variant="standard" key={index + 1} label={`Channel Link ${index + 2}`} type="text" value={link} onChange={(e) => handleChannelLinkChange(e, index + 1)} />
      ))}
      {channelLink.length < 3 && (
      <Button onClick={addChannelLink}>Add Channel Link</Button>
      )} */}
      <p style={{ color: profileError === "Details updated successfully" ? 'blue' : 'red' }}>{profileError}</p>
      <Button type="submit">Update</Button>
    </form>
    </div>

    
    <div>
      <h2>Billing Details</h2>
    <form onSubmit={handleAccountSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TextField variant="standard" label="Account Holder Name" type="text" name="accountName" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
      <TextField variant="standard" label="Account Number" type="text" name="accountNumber" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
      <TextField variant="standard" required label="IFSC Code" type="text" name="ifsc" value={ifsc} onChange={(e) => setIfsc(e.target.value)} />
      <TextField variant="standard" label="Bank Name and Branch" type="text" name="bankDetails" value={bankDetails} onChange={(e) => setBankDetails(e.target.value)} />
      <p style={{ color: billingError === "Details updated successfully" ? 'blue' : 'red' }}>{billingError}</p>
      <Button type="submit">Update</Button>
    </form>
    </div>
    </>
  );
};

export default Profile;
