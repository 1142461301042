import React, { useState } from "react";
import { TextField, Button, Switch } from "@mui/material";
import { pink } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { updateUser } from "../../api/userService";
import CopyToClipboard from "../CopytoClipboard/CopytoClipboard";

interface AdSetupProps {
  user: any;
}

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const AdSetup: React.FC<AdSetupProps> = ({ user }) => {
  const [checked, setChecked] = useState<boolean>(user.isLive);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeout, setTimeout] = useState<number>(user.timeout);
  const [charge, setCharge] = useState<number >(user.charge);
  const [error, setError] = useState<string | null>(null);
  const obsLink = `https://streamers.media/player/${user.id}/${user.MagicNumber}`;
  const paymentLink = `https://streamers.media/upload/${user.id}`;


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if(!charge || !timeout) {
        setError("Please fill all the fields");
        return;
      }
      if(charge <= 15 || timeout <= 15) {
        setError("Charge and Timeout should be greater than 15");
        return;
      }
      const response = await updateUser({ charge, timeout });
      if (response.status === 200) {
        setError("Details updated successfully");
          } else {
            setError(`Error updating details`);
          }

      } catch (error) {
        setError("Error updating details");
      }
    }


  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setLoading(true);
    try {
      const response = await updateUser({ isLive: newChecked });
      if (response.status === 200) {
        setChecked(newChecked);
      } else {
        setError(`Error updating details`);
      }
    } catch (error) {
      alert("Error updating details");
    }
    setLoading(false);
  }   

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
        <div>
            <h3 style={{ margin: 0 }}>Update Your Ads Status</h3>
            <p style={{ margin: 0 }}>
            Use the switch below to enable/disable ads on your stream. Turning it off will stop ads from being displayed, and your page will be updated accordingly for advertisers. Advertisers will also not be able to upload ads when disabled.
            </p>
        </div>
        <PinkSwitch
          checked={checked}
          onChange={handleChange}
          disabled={loading}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>

    <div> 
      <h2>Update Ads Settings</h2>
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TextField variant="standard" required label="Cost per Second" type="number" name="charge" value={charge} onChange={(e) => setCharge(Number(e.target.value))} />
      <TextField variant="standard" label="Time Gap between 2 Ads" type="number" name="timeout" value={timeout} onChange={(e) => setTimeout(Number(e.target.value))} />
      <p style={{ color: error === "Details updated successfully" ? 'blue' : 'red' }}>{error}</p>
      <Button type="submit">Update</Button>
    </form>
    </div>
    <h2>Setup Instructions</h2>
    <div className="links">
          <h3>OBS Link</h3>
      <div className="obs-link-box"  style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
          <TextField value={obsLink} InputProps={{ readOnly: true }} style={{ width: "50%", minWidth:'350px',}} />
          <CopyToClipboard text={obsLink} />
      </div>
          <p style={{ color: 'red' }}>Note: Do not share this link outside</p>
          <p>Use the following URL in your OBS Studio to start streaming with live ads. Ensure you are live on <strong>STREAMERS</strong> when it is active. Follow the setup instructions below:</p>
          <ol>
              <li>Copy your Overlay URL by clicking the <strong>Copy</strong> button above.</li>
              <li>Add your overlay to your OBS as <strong>Browser Source</strong>.</li>
              <li>Set Width to <strong>1920</strong> and Height to <strong>1080</strong>. Width and Height should match your Video &gt;&gt;&gt; Base (Canvas) Resolution.</li>
              <li>Check <strong>Refresh browser when scene becomes active</strong> checkbox.</li>
              <li>Click <strong>Confirm</strong> button below.</li>
          </ol>
      <br />
      <h3>Advertisement Upload Link</h3>
      <p>Share this link in your streams or their description, so that advertisers can go here and advertise on your stream.</p>
      <div className="payment-link-box" style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
          <TextField value={paymentLink} InputProps={{ readOnly: true }} style={{ width: "50%", minWidth: '350px' }} />
          <CopyToClipboard text={paymentLink}  />
      </div>
      </div>
    </>
  );
};

export default AdSetup;
