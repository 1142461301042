export const BACKEND_API_URL = 'https://api.streamers.media'; 
export const CLOUDINARY_API_URL='cloudinary://213425859692937:Wsj-WrPbbDcfeR23d4750kxKZRo@dzfctqaez'
export const CLOUDINARY_API_KEY='213425859692937'
export const CLOUDINARY_CLOUD_NAME='dfuh0xdnd'
export const CLOUDINARY_API_SECRET='Wsj-WrPbbDcfeR23d4750kxKZRo'
export const RAZORPAY_KEY_SECRET='dVROGmgL8iQSziCXNBW0823j'
export const RAZORPAY_KEY_ID='rzp_live_fHjnZYkysnjTpx'
export const SIGHTENGINE_CALLBACK_URL='https://streamers.media/upload'
export const SIGHTENGINE_API_USER='1209847355'
export const SIGHTENGINE_API_SECRET='GhhbpNhbjnRNLdCzn3gHYZMXRA69ju4b'
export const JWT_SECRET='34dfvd322'