import React, { useEffect, useState } from 'react';
import { fetchPaymentsForYoutuber, fetchPaymentsForCompany } from '../../api/userService';

const YoutuberPayments = ({user} : any) => {
  interface Payment {
    amount: number;
    currency: string;
    status: string;
    orderId: string;
    createdAt: string;
    youtuber: {
      name: string;
      charge: number;
    };
  }

  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);
  console.log(user);

  useEffect(() => {
    const getPayments = async () => {
      let response;
      if (user.type === 'youtuber') {
        response = await fetchPaymentsForYoutuber(user.id as string);
      } else {
        response = await fetchPaymentsForCompany(user.id as string);
      }
      console.log(response.data);
      if (response.data) {
        setPayments(response.data);
      }
      setLoading(false);
    };

    getPayments();
  }, [user.id]);

  if (loading) {
    return <div>Loading payments...</div>;
  }

  return (
    <div>
      <h2>Payments</h2>
      {user && <p>User Name: {user.name}</p>}
      <p>Here are your payments:</p>
      <ul>
        {payments.length === 0 ? (
            <li>No payments found.</li>
          ) : (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>Amount</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Youtuber</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Status</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Order ID</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Date</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{payment.amount} {payment.currency}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{payment.youtuber.name}</td>
                <td style={{ border: '1px solid black', padding: '8px', color: payment.status === 'pending' ? 'red' : 'green' }}>{payment.status}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{payment.orderId}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{new Date(payment.createdAt).toLocaleString()}</td>
              </tr>
              ))}
            </tbody>
            </table>
          )
        }
      </ul>
    </div>
  );
};

export default YoutuberPayments;
