import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    FormControl,
    Button,
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import { uploadMedia } from "../../api/UploadMedia";
import {useRazorpay} from 'react-razorpay';
import { getUsernameById } from "../../api/userService";


type ResultType = {
    username?: string;
    charge?: number;
    isLive?: boolean;
    message?: string;
};

const UploadAd: React.FC = () => {
    const { userId } = useParams<{ userId: string }>(); // Extract userId from URL
    const companyId= localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}').id : '';
    console.log(companyId);
    const [file, setFile] = useState<File | null>(null);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [videoDuration, setVideoDuration] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false); // State to manage loading
    const {Razorpay}= useRazorpay();
    const [username, setUsername] = useState<string | null>(null);
    const [charge, setCharge] = useState<number | null>(null);
    const [isUsernameLoading, setIsUsernameLoading] = useState<boolean>(true);


    useEffect(() => {
        const fetchUsername = async () => {
            setIsUsernameLoading(true);

            if (userId) {
                const result: ResultType = await getUsernameById(userId);
                console.log(result.isLive);

                if ('username' in result && 'charge' in result && 'isLive' in result) {
                    setUsername(result.username ?? null);
                    setCharge(result.charge ?? null);
                    console.log(result.charge);
                } else {
                    if ('message' in result) {
                        alert(result.message);
                    }
                    window.location.href = "/"; // Redirect to home page if user not found
                    setIsUsernameLoading(false);
                    return;
                }

                if (!result.isLive) {
                    alert(`The streamer ${result.username} is currently not accepting advertisements. Please try again later.`);
                    window.location.href = "/"; // Redirect to home page if user not live
                }
            }

            setIsUsernameLoading(false);
        };

        fetchUsername();
    }, [userId]);

    
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            extractVideoDuration(droppedFile);
        }
        setIsHovered(false);
    };
    
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = e.target.files?.[0];
        if (uploadedFile) {
            setFile(uploadedFile);
            extractVideoDuration(uploadedFile);
        }
    };

    const extractVideoDuration = (file: File) => {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(file);

        videoElement.onloadedmetadata = () => {
            setVideoDuration(videoElement.duration);
            if (videoElement.duration > 30) {
            alert("Maximum video duration is 30 seconds so the initial 30 seconds will be uploaded");
            setVideoDuration(30);
            }
        };
    };

    const handleSubmit = async () => {
        if (file) {
            setIsLoading(true);  // Set loading to true when the process starts
            try {
                if(!userId){
                    return alert("Please provide a valid Streamer");
                }
                if (videoDuration !== null) {
                    if (charge !== null) {
                        const totalCharge = Number((videoDuration * charge).toFixed(2));
                        await uploadMedia(file, userId, companyId, videoDuration, totalCharge, Razorpay); // Pass userId and companyId
                    } else {
                        alert("Charge is not available. Please try again.");
                    }
                    alert("Media uploaded successfully!");
                } else {
                    alert("Failed to get video duration. Please try again.");
                }
                alert("Media uploaded successfully!");
            } catch (error) {
                alert("Failed to upload media. Please try again.");
            } finally {
                setIsLoading(false);  // Set loading to false when the process finishes
            }
        } else {
            alert("Please select a media file to upload.");
        }
    };

    const handleDragEnter = () => {
        setIsHovered(true);
    };

    const handleDragLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
        {isUsernameLoading ? (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '95vh',
                }}
            >
                <CircularProgress />
            </Box>
        ) : (
            <div style={{ minHeight: '95vh' }}>
            <Box sx={{ p: { xs: 1, sm: 2, md: 3, lg: 4 } }} alignItems={'center'}>
                <Typography variant="h5" gutterBottom>
                    Upload Your Advertisement for Streamer: {username || "Loading..."}
                </Typography>
                <FormControl variant="standard" fullWidth>
                <Box
                        sx={{
                            my: { xs: 2, sm: 3, md: 4, lg: 5 },
                            margin: "auto",
                            textAlign: "center",
                            width: "80%",
                            height: "100%",
                            border: "2px dashed #ccc",
                            borderRadius: "20px",
                            minWidth: "300px",
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Responsive layout
                            alignItems: "center", // Center items vertically
                            justifyContent: "center", // Center items horizontally
                            alignContent: "center",
                            padding: 2, // Add padding if needed
                            mb: 2,
                            backgroundColor: isHovered ? "grey" : "#fff",
                            color: isHovered ? "#fff" : "#000",
                            transition: "background-color 0.3s ease",
                            position: "relative",
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                    >
                    {file ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', position:'absolute', flexWrap:'wrap', height:'100%', justifyContent:'center', alignContent:'space-around' }}>
                            {/* Video preview */}
                            {isHovered ? <></> : 
                            <div style={{
                                width: '350px',
                                height: '200px', // Set height to auto
                                borderRadius: '20px',
                                position: 'relative',
                                overflow: 'hidden',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                border: '2px solid #ccc',
                            }}>
                                <video
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        maxWidth: '40%',
                                        maxHeight: '60%',
                                        objectFit: 'contain',
                                        zIndex: 10,
                                    }}
                                    src={URL.createObjectURL(file)}
                                    loop
                                    muted
                                    autoPlay
                                />
                            </div>
                            
                            }
    
                            {/* Content displayed on the side of the video */}
                            <div style={{
                                paddingLeft: '15px', // Add some space between video and content
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxWidth: '450px',
                                
                            }}>
                                <span>{isHovered ? "" : `Uploaded file: ${file.name}`}</span>
                                <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{isHovered ? "Drop it like it's hot!" : `Duration: ${videoDuration ? `${Math.round(videoDuration)} seconds` : "Loading..."}`}</span>
                                <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{isHovered ? "" : `Charges: ₹${charge && videoDuration !== null ? (videoDuration * charge).toFixed(2) : "Loading..."} (Rate: ₹${charge}/second)`}</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                        }}>
                            {isHovered ? null : <img src="/dropfile.png" alt="Drop file here" style={{ height: '150px', paddingTop: '15px' }} />}
                            <p>{isHovered ? "Drop it like it's hot!" : "Drag and drop a file here or click to upload"}</p>
                        </div>
                    )}
                    <input type="file" accept="video/*" onChange={handleFileChange} style={{ width: '100%', minHeight: "300px", minWidth: "300px", opacity: '0' }} />
                </Box>
    
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    style={{
                        height: '60px',
                        width: '50%',
                        maxWidth: '250px',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        margin: 'auto',
                    }}
                    disabled={!file || isLoading} // Disable button if loading
                >
                    {isLoading ? <CircularProgress size={24} /> : "Continue"}  {/* Show loading spinner when isLoading */}
                </Button>
                </FormControl>
            </Box>
            </div>
        )}
        </>
    );
    
};

export default UploadAd;
